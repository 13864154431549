import React from "react";
import classes from "./Logo.module.css";

import logoImage from "../../assets/images/icon512.png";
import LogoTileRow from "./LogoTileRow";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Logo = (props) => {
  const opacity = props.opacity;
  const placedLeft = props.placeLeft;
  const width = useWindowDimensions().width;
  const imgPixelWidth = width > 1000 ? 256 : width > 500 ? 128 : 64;
  const tilesOnSide = 5;
  

  const sideVal = placedLeft ? { left: "5%" } : { right: "5%" };

  return (
    <div
      className={classes.imageContainer}
      style={{ opacity: opacity, ...sideVal }}
    >
      <div className={classes.tileParent}>
        {[...Array(tilesOnSide)].map((e, i) => (
          <LogoTileRow rowindex={i} key={i} tilesonside={tilesOnSide} totalImgPixelWidth={imgPixelWidth}/>
        ))}
      </div>
      <img alt="logo" src={logoImage} height={imgPixelWidth} className={classes.image} />
    </div>
  );
};


export default Logo;
