import "./App.css";
import { useState } from "react";

import Card from "./components/UI/Card";
import Header from "./components/UI/Header";
import Mastermind from "./components/UI/Mastermind";
import Logo from "./components/logo/Logo";

function App() {
  const [gameActive, setGameActive] = useState(false);

  const toggleGameActive = () => {
    setGameActive((prevState) => {
      return !prevState;
    });
  };

  return (
    <div className="App">
      <Header title="Evig nedetid" />
      <div>
        <Logo opacity="0.05" placeLeft={true} />
        {!gameActive ? (
          <Card toggleGameHandler={toggleGameActive} />
        ) : (
          <Mastermind toggleGameHandler={toggleGameActive} />
        )}
        <Logo opacity="0.05" placeLeft={false} />
      </div>
    </div>
  );
}

export default App;
