import React from "react";
import classes from "./LogoTile.module.css";

const LogoTile = (props) => {
  const columnIndex = props.columnindex;
  const lineNumber = props.linenumber;
  const tileWidth = props.tilepixelwidth;
  const columnNumber = columnIndex + 1;

  const animDelay = 1 + 0.2 * lineNumber + 0.2 * columnNumber;

  return (
    <div
      className={classes.tile}
      style={{ animationDelay: `${animDelay}s`, width: `${tileWidth}px` }}
    ></div>
  );
};

export default LogoTile;
