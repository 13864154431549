import React from "react";

import classes from './Header.module.css';

const Header = (props) => {
  const title = props.title;

  return <div className={classes.mainheader}>
    <p className={classes.title}>{title}</p>
  </div>
};


export default Header;