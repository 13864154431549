import React from "react";

import classes from './Mastermind.module.css';

const Mastermind = (props) => {
  const toggleGameActive = props.toggleGameHandler;

  return (
    <div className={classes.stack}>
      <iframe title="Mastermind" src="https://i.simmer.io/@lowKeyEnt/mastermind" className={classes.gameFrame}></iframe>
      <button className={classes.backBtn} onClick={() => toggleGameActive()}>Tilbake</button>
    </div>
  );
};

export default Mastermind;
