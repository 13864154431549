import React from "react";
import classes from './LogoTileRow.module.css';

import LogoTile from "./LogoTile";

const LogoTileRow = (props) => {
  const lineNumber = props.rowindex + 1;
  const tilesOnSide = props.tilesonside;
  const imgPixelWidth = props.totalImgPixelWidth;

  const tileSideLength = Math.ceil(imgPixelWidth / tilesOnSide);

  return <div className={classes.tileRow} style={{height: `${tileSideLength}px`, width: `${imgPixelWidth}px`}}>{[...Array(tilesOnSide)].map((e, i) => (
    <LogoTile key={i} columnindex={i} linenumber={lineNumber} tilepixelwidth={tileSideLength}/>
  ))}</div>
};

export default LogoTileRow;