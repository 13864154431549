import React from 'react';
import classes from './Card.module.css';
import treeroot from '../../assets/images/root.jpg';

const Card = (props) => {
  const toggleGameActive = props.toggleGameHandler;

  const buttonHandler = () => {
    toggleGameActive();
  }

  return <div className={classes.card}>
    <img alt='tree with root' src={treeroot} className={classes.image}/>
    <p>Denne siden er tenkt som et sted for å teste ut features og vil kanskje inneholde mer stoff etter hvert. Eller... Skal jeg være helt ærlig er nok dette et hobbyprosjekt som sannsynligvis forfaller og blir glemt over tid. Men jeg fikk da publisert om ikke annet.</p>
    <p>For de med alt for god tid:  <br/>test <button onClick={() => buttonHandler()}>Mastermind</button></p>
  </div>
};

export default Card;